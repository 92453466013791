<template>
    <div fluid v-if="!fetchingSpaces" flat>
        <template v-if="tablesFetching !== true && currentTables.length > 0">
            <v-row>
                <v-col cols="12">
                    <v-switch
                        v-if="currentTables.length > 0"
                        hide-details
                        class="my-0 pt-6 mx-2"
                        v-model="showSqlEditor"
                        label="SQL Editor"
                        e2e-snapshot-tables-sql-switch></v-switch>

                    <div v-if="showSqlEditor" class="mt-3">
                        <v-sheet class="rounded overflow-hidden">
                            <div class="d-flex align-center" style="background-color: #43e0cf">
                                <span class="caption ma-2">SQL Editor</span>
                                <v-spacer></v-spacer>
                                <div class="ma-2">
                                    <v-btn text small v-if="!fullEditor" @click="fullEditor = true">
                                        <v-icon small>open_in_full</v-icon>
                                        Expand
                                    </v-btn>
                                    <v-btn text small v-else @click="fullEditor = false">
                                        <v-icon small>close_fullscreen</v-icon>
                                        Shrink
                                    </v-btn>
                                </div>
                            </div>
                            <monaco-editor
                                class="monaco-editor"
                                :style="{ height: fullEditor ? 'calc(100vh - 350px)' : '88px' }"
                                v-model="sqlQuery"
                                language="sql"
                                :options="{
                                    minimap: { enabled: false },
                                    fontSize: 14,
                                    renderLineHighlight: false,
                                    automaticLayout: true,
                                    autoIndent: true,
                                    lineNumbersMinChars: 5,
                                    scrollbar: { vertical: 'visible', alwaysConsumeMouseWheel: false },
                                    scrollBeyondLastLine: false,
                                    smoothScrolling: true
                                }" />
                        </v-sheet>
                        <query-submit
                            :mode="queryModeOptions.PREVIEW"
                            buttonName="preview"
                            tooltipHint="Returns a preview sample of the query outcome."
                            :iconShaped="false"
                            :consoleSql="sqlQuery"
                            @success="submitQuery"></query-submit>
                    </div>
                </v-col>
            </v-row>
            <v-row class="mt-5" align="center">
                <v-col cols="7">
                    <v-tabs height="25" v-model="tabView" show-arrows>
                        <v-tab dark height="10">
                            <span class="caption">tables ({{ currentTables.length }})</span>
                        </v-tab>
                        <v-tab dark height="10" :disabled="!currentColumns.length">
                            <span class="caption">columns ({{ currentColumns.length }})</span>
                        </v-tab>
                        <v-tab dark height="10" :disabled="!selectedTableForPreview">
                            <span class="caption">table preview</span>
                        </v-tab>
                    </v-tabs>
                </v-col>
            </v-row>

            <v-tabs-items v-model="tabView">
                <v-tab-item class="mt-5">
                    <SnapshotTableList @switchTab="tabView = $event.tab"></SnapshotTableList>
                </v-tab-item>
                <v-tab-item class="mt-3">
                    <SnapshotTableColumns />
                </v-tab-item>
                <v-tab-item class="mt-3">
                    <SnapshotTablePreview
                        :columnsData="previewData"
                        :headersData="previewColumnHeaders"
                        :fetchingData="previewFetching"
                        :tableName="selectedTableForPreview" />
                </v-tab-item>
            </v-tabs-items>
        </template>
        <div v-else-if="tablesFetching === true" class="mt-5">
            <div>
                <v-skeleton-loader class="mx-auto" type="text"></v-skeleton-loader>
            </div>
            <div>
                <v-skeleton-loader class="mx-auto" type="list-item-three-line" v-for="(n, i) in 3" :key="i"></v-skeleton-loader>
            </div>
        </div>
        <v-banner class="mt-5" v-else-if="tablesFetching === false && !currentTables.length" single-line>
            <v-avatar slot="icon" color="white" size="60">
                <v-icon x-large icon="info" color="info">info</v-icon>
            </v-avatar>
            <div class="d-flex flex-column">
                <span class="font-weight-bold">No tables found</span>
                <span v-if="isDevelopment">
                    No tables were found. This might be because there are no tables in this space or tables have not been activated.
                </span>
                <span v-else>No tables were found in this snapshot.</span>
            </div>
            <template v-slot:actions>
                <v-btn @click="$store.dispatch('snapshotStore/fetchCurrentTables', $route.params.snid)" text small outlined>Refresh</v-btn>
            </template>
        </v-banner>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import 'github-markdown-css/github-markdown.css'
import { enumsData } from '@/mixins/enums'
import { queryData } from '@/mixins/query'
import eventBus from '@/components/EventBus'

const SnapshotTablePreview = () => import('../components/TheSnapshotTablePreview.vue')
const SnapshotTableColumns = () => import('../components/TheSnapshotTableColumns.vue')
const SnapshotTableList = () => import('../components/TheSnapshotTableListIterator.vue')

const MonacoEditor = () => import('vue-monaco')
const QuerySubmit = () => import('../components/TheSnapshotSubmitQuery.vue')

export default {
    mixins: [enumsData, queryData],
    components: {
        SnapshotTablePreview,
        SnapshotTableColumns,
        SnapshotTableList,
        QuerySubmit,
        MonacoEditor
    },
    data() {
        return {
            tabView: 0,
            showSqlEditor: false,
            fullEditor: false,
            sqlQuery: ''
        }
    },
    methods: {
        submitQuery() {
            eventBus.$emit('querySubmitSuccess')
        }
    },
    computed: {
        ...mapState('snapshotStore', [
            'currentTables',
            'currentColumns',
            'tablesFetching',
            'selectedTableForPreview',
            'previewFetching',
            'previewData',
            'previewColumnHeaders'
        ]),
        ...mapGetters('snapshotStore', ['isDevelopment']),
        ...mapState('orgStore', ['fetchingSpaces'])
    }
}
</script>
